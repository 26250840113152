/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-google-recaptcha';
import { SITE_CONFIG } from '../config/constants';

const ContactForm = ({ labels, placeholders, submitText, actionUrl, onSubmit }) => {
  const [state, setState] = useState({});
  const recaptchaRef = React.createRef();
  const siteKey = SITE_CONFIG['googleRecaptchaSiteKey'];

  if (!actionUrl) {
    return;
  }
  if (typeof siteKey === 'undefined') {
    throw new Error(`
    Env var SITE_RECAPTCHA_KEY is undefined! 
    You probably forget to set it in your Netlify build environment variables. 
    Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
    Note this demo is specifically for Recaptcha v2
    `);
  }

  let recaptchaContent;

  if (siteKey) {
    recaptchaContent = <Recaptcha ref={recaptchaRef} sitekey={siteKey} />;
  }

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue) return;

    fetch(actionUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          if (onSubmit) {
            onSubmit(true);
          }
        }
      })
      .catch((error) => alert(error));
  };

  return (
    <form
      className="grid grid-cols-1 gap-3 lg:col-span-2"
      name="contact"
      method="post"
      data-netlify="true"
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <noscript>
        <p>This form won’t work with Javascript disabled</p>
      </noscript>
      <div className="hidden">
        <label htmlFor="inputBot">
          Don’t fill this out if you&apos;re human:
          <input id="inputBot" name="bot-field" />
        </label>
      </div>
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-5">
        <div className="">
          <label className="mb-3 block" htmlFor="inputName">
            {labels.name}
          </label>
          <input
            type="text"
            name="name"
            placeholder={placeholders.name}
            className="form-input w-full rounded-lg border-0 bg-slate-200 px-4 py-2 text-slate-600 placeholder:text-slate-600"
            id="inputName"
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label className="mb-3 block" htmlFor="inputEmail">
            {labels.email}
          </label>
          <input
            type="email"
            name="email"
            placeholder={placeholders.email}
            className="form-input w-full rounded-lg border-0 bg-slate-200 px-4 py-2 text-slate-600 placeholder:text-slate-600"
            id="inputEmail"
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="grid grid-rows-1">
        <label className="mb-3 block" htmlFor="inputSubject">
          {labels.subject}
        </label>
        <input
          type="text"
          name="subject"
          placeholder={placeholders.subject}
          className="form-input rounded-lg border-0 bg-slate-200 px-4 py-2 text-slate-600 placeholder:text-slate-600"
          id="inputSubject"
          onChange={handleChange}
          required
        />
      </div>
      <div className="grid grid-rows-1">
        <label className="mb-3 block" htmlFor="inputMessage">
          {labels.message}
        </label>
        <textarea
          name="message"
          placeholder={placeholders.message}
          className="form-input rounded-lg border-0 bg-slate-200 px-4 py-2 text-slate-600 placeholder:text-slate-600"
          id="inputMessage"
          rows="4"
          onChange={handleChange}
          required
        />
      </div>
      <div className="mt-3 flex flex-col justify-between md:flex-row">
        {recaptchaContent}
        <button type="submit" className="btn mt-8 md:my-auto">
          {submitText}
        </button>
      </div>
    </form>
  );
};

ContactForm.defaultProps = {
  labels: {
    name: '',
    email: '',
    subject: '',
    message: '',
  },
  placeholders: {
    name: '',
    email: '',
    subject: '',
    message: '',
  },
  actionUrl: '',
  submitText: 'Send Message',
  onSubmit: null,
};

ContactForm.propTypes = {
  labels: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    subject: PropTypes.string,
    message: PropTypes.string,
  }),
  placeholders: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    subject: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  actionUrl: PropTypes.string,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default ContactForm;
