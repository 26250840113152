import React from 'react';
import PropTypes from 'prop-types';
import UniversalLink from './UniversalLink';

const SocialMedia = ({ text, twitter, linkedin, github, className }) => {
  let textContent;
  let twitterContent;
  let linkedinContent;
  let githubContent;

  if (text) {
    textContent = <p className="text-md my-auto text-slate-600 dark:text-slate-400">{text}</p>;
  }
  if (twitter) {
    const { label, url, icon } = twitter;
    if (icon) {
      const Twitter = icon;
      twitterContent = (
        <UniversalLink
          to={url}
          className="text-base text-slate-500 hover:underline"
          aria-label={label}
          target="_blank"
          rel="noreferrer"
        >
          <Twitter className="h-8 text-3xl text-slate-400 hover:text-sky-400" />
        </UniversalLink>
      );
    }
  }
  if (linkedin) {
    const { label, url, icon } = linkedin;
    if (icon) {
      const LinkedIn = icon;
      linkedinContent = (
        <UniversalLink
          to={url}
          className="text-base text-slate-500 hover:underline"
          aria-label={label}
          target="_blank"
          rel="noreferrer"
        >
          <LinkedIn className="h-8 text-3xl text-slate-400 hover:text-sky-700" />
        </UniversalLink>
      );
    }
  }
  if (github) {
    const { label, url, icon } = github;
    if (icon) {
      const Github = icon;
      githubContent = (
        <UniversalLink
          to={url}
          className="text-base text-slate-500 hover:underline"
          aria-label={label}
          target="_blank"
          rel="noreferrer"
        >
          <Github className="h-8 text-3xl text-slate-400 hover:text-slate-700 dark:hover:text-slate-100" />
        </UniversalLink>
      );
    }
  }
  return (
    <div className={className ? className : ''}>
      {textContent}
      {twitterContent}
      {linkedinContent}
      {githubContent}
    </div>
  );
};

SocialMedia.defaultProps = {
  text: '',
  twitter: null,
  linkedin: null,
  github: null,
  className: null,
};

SocialMedia.propTypes = {
  text: PropTypes.string,
  twitter: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.func.isRequired,
  }),
  linkedin: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.func.isRequired,
  }),
  github: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.func.isRequired,
  }),
  className: PropTypes.string,
};

export default SocialMedia;
