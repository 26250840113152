import React, { useState } from 'react';
import SocialMedia from '../components/SocialMedia';
import TwitterIcon from '../assets/svg/twitter.inline.svg';
import LinkedinIcon from '../assets/svg/linkedin.inline.svg';
import GithubIcon from '../assets/svg/github.inline.svg';
import ContactForm from '../components/ContactFormWithRecaptcha';
import { SITE_CONFIG } from '../config/constants';

const ContactPage = () => {
  const contactFormActionUrl = SITE_CONFIG['contactFormActionUrl'];
  const [isSubmited, setSubmit] = useState(false);
  let formContent;

  const handleSubmittedForm = (status = false) => {
    setSubmit(status);
  };

  const cardContent = (
    <div className="rounded-xl border border-slate-300 p-8 text-slate-800 dark:border-slate-500 dark:text-white">
      <p className="font-bold">Are you working on something great?</p>
      <p>
        I would love to help make it happen! Drop me a letter and start your project right now! Just
        do it.
      </p>
      <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et..</p>
      <SocialMedia
        className="mt-3 ml-3 flex justify-end space-x-4"
        twitter={{
          label: 'Twitter',
          url: 'https://twitter.com/AndreasFrontDev',
          icon: TwitterIcon,
        }}
        linkedin={{
          label: 'Linkedin',
          url: 'https://cy.linkedin.com/in/andreaseracleous',
          icon: LinkedinIcon,
        }}
        github={{
          label: 'Github',
          url: 'https://github.com/AndreasEracleous',
          icon: GithubIcon,
        }}
      />
    </div>
  );

  formContent = (
    <div className="grid gap-10 xl:grid-cols-3">
      <ContactForm
        labels={{
          name: 'Name',
          email: 'Email address',
          subject: 'Subject',
          message: 'Message',
        }}
        placeholders={{
          name: 'Your Name',
          email: 'Your Email',
          subject: 'Your Subject',
          message: 'Your Message',
        }}
        actionUrl={contactFormActionUrl}
        submitText="Send Message"
        onSubmit={handleSubmittedForm}
      />
      {cardContent}
    </div>
  );

  if (isSubmited) {
    formContent = (
      <div className="mx-auto grid grid-cols-1 gap-3 rounded-xl border border-slate-300 p-8 dark:border-slate-500 lg:col-span-2">
        <h3 className="my-0 text-xl font-bold text-emerald-500">Thank you!</h3>
        <p className="mb-0">Thank you for your enquiry, we will be in touch as soon as possible.</p>
      </div>
    );
  }
  const headerSection = <h1 className="mt-0 mb-5 text-center text-4xl">Contact</h1>;
  const descriptionContent = (
    <p className="mx-auto mb-10 max-w-3xl text-center">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
      labore et dolore magna aliqua. Sit amet commodo nulla facilisi nullam vehicula ipsum.
    </p>
  );
  return (
    <>
      {headerSection}
      {descriptionContent}
      <div className="rounded-xl bg-white p-5 shadow dark:bg-slate-700 md:p-10">{formContent}</div>
    </>
  );
};

export default ContactPage;
